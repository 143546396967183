import React, { Component } from 'react';
import ExtLink from '../../components/elements/ExtLink/ExtLink';

class HomePageTeaser extends Component {

  render() {
    const teasers = this.props.teasers;

    const teaserList = teasers.map((teaser, i) => {
      return (
        <div className="hp-teasers col-6 col-md-3 mb-4" key={i}>
          {teaser.hp_teaser_lnk ? (
            <ExtLink title={teaser.hp_teaser_lnk.title} className="hp-teasers__lnk" href={teaser.hp_teaser_lnk.url} target={teaser.hp_teaser_lnk.target}>
            {teaser.hp_teaser_txt}
            <div className="hp-teasers__img">
              <img width={teaser.hp_teaser_img.sizes['large-width']} height={teaser.hp_teaser_img.sizes['large-height']} 
                src={teaser.hp_teaser_img.sizes.large} 
                className="d-block w-100" 
                alt={teaser.hp_teaser_img.title} 
                srcSet={teaser.hp_teaser_img.sizes.large + ' 1600w,' +  teaser.hp_teaser_img.sizes.medium + ' 800w' } sizes="(max-width: 1600px) 100vw, 1600px"
              />
              <div className="hp-teasers__gradient gray mink-vertical-gradient_"><div></div></div>
            </div>
          </ExtLink>
        ):(
          <div className="hp-teasers__lnk">
            {teaser.hp_teaser_txt}
            <div className="hp-teasers__img">
              <img width={teaser.hp_teaser_img.sizes['large-width']} height={teaser.hp_teaser_img.sizes['large-height']} 
                src={teaser.hp_teaser_img.sizes.large} 
                className="d-block w-100" 
                alt={teaser.hp_teaser_img.title} 
                srcSet={teaser.hp_teaser_img.sizes.large + ' 1600w,' +  teaser.hp_teaser_img.sizes.medium + ' 800w' } sizes="(max-width: 1600px) 100vw, 1600px"
              />
              <div className="hp-teasers__gradient gray mink-vertical-gradient_"><div></div></div>
            </div>
          </div>

        )}

        </div>
      )

    });
    return (
      <div className="row">
        {teaserList}
      </div>
    );

  }
}

export default HomePageTeaser;
