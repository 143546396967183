import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parseUrlComponents } from '../../../helpers/api';


export default class ExtLink extends Component {

  render() {
    const {title, href, target, className, ...props } = this.props;
    const urlComponents = parseUrlComponents(href);
      if ('_blank' !== target) {
        return <Link className={className ? className : ''} to={urlComponents.pathname} title={title} {...props}>{this.props.children}</Link>
      } else {
        return <a className={className ? className : ''} href={href} target="_blank" title={title} {...props} rel="noopener noreferrer">{this.props.children}</a>
      }
  }
}