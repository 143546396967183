import React, { Component } from 'react';
import {renderText} from '../../../helpers/api';
import {Button} from 'reactstrap';

class FilterTermButton extends Component {

  render() {
    const cat = this.props.cat;
    return (
      <Button color={ this.props.active ? 'primary' : 'outline-primary'} className={'kn-posts-filter__item'} onClick={this.props.onClick.bind(this, cat)}>
        {renderText(cat.name)}
      </Button>
    );
  }

}

export default FilterTermButton;
