import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import NoMatch  from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
// import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SecondaryNav from '../SecondaryNav/SecondaryNav' 
import SingleContent from './SingleContent';
import { getDefaultLang } from '../../helpers/lang';

@inject('pagesStore', 'navigationStore')
@observer
class Single extends Component {

  slug = '';
  store = {};

  constructor(props) {
    super(props);
    this.getCurrentSlug();
    this.getCurrentLang();
    this.store = this.props.store ? this.props.store : this.props.pagesStore;
  }

  renderHead() {
    if (this.store.isFetched.get(this.slug) && this.store.data.posts[this.slug].title) {
      return renderTitle(this.store.data.posts[this.slug].title);
    } else {
      return null;
    }
  }

  getCurrentSlug = () => {
    if (this.props.match && this.props.match.params) {
      if (this.props.apiParams && this.props.apiParams.postType) {
        this.slug = this.props.location.pathname;
      } else {
        const slug = this.props.match.params.id;
        this.slug = slug ? slug : 'homepage';
      }
    }
  }
  getCurrentLang = () => {
    if (this.props.match && this.props.match.params) {
      const lang = this.props.match.params.lang;
      this.lang = lang ? lang : getDefaultLang();
    }
  }

  componentDidMount() {
    if (this.props.apiParams && this.props.apiParams.postType) {
      this.store.fetchPathIfNeeded(this.slug);
    } else {
      this.store.fetchIfNeeded(this.slug, this.lang);
    }

    document.body.classList.add('page');
    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.add('single-' + postType);
      this.props.navigationStore.setActivePostLangs(this.store.data.posts[this.slug].translations);
    }
  }
  componentWillUnmount() {
    document.body.classList.remove('page');
    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.remove(postType, 'single-' + postType);
    }

  }

  componentDidUpdate() {
    this.getCurrentSlug();
    this.getCurrentLang();
    if (this.props.apiParams && this.props.apiParams.postType) {
      this.store.fetchPathIfNeeded(this.slug);
    } else {
      this.store.fetchIfNeeded(this.slug, this.lang);
    }

    if (this.store.isFetched.get(this.slug)) {
      const postType = this.store.data.posts[this.slug].post_type;
      document.body.classList.add('single-' + postType);
      this.props.navigationStore.setActivePostLangs(this.store.data.posts[this.slug].translations);
    }
  }

  render() {
    const isFetched = this.store.isFetched.get(this.slug);
    const post = this.store.data.posts[this.slug];
    let terms = false;

    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);
    if ( isFetched ) {
      post.terms && post.terms.category && post.terms.category.map ((term) => {
        if( 'concept' === term.slug ||  'concept-en' === term.slug ) {
          terms = "concept";
        } else if ( 'contest' === term.slug ||  'contest-en' === term.slug ){
          terms = "contest";
        }
        return false;
      });      
    }

    if ((isFetched && false === post) || (this.store.isFetchedAll && !post)) {
      return <NoMatch/>;
    }

    return (
      <div className="mink-page">
        { isFetched ? (
          <div>
            <div className="mink-content " data-test={post.post_type}>
              {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
              <ScrollToHash/>
              {this.renderHead()}
              {/* <Header/> */}
              {'post' === post.post_type ? (<SecondaryNav terms={terms} />):( <h2 className="mink-page__head mt-5 mb-3">{renderText(post.title)}</h2>)}
              <div className={'row'}>
                <div className="col-12 -post-content">
                  {post.fields && post.fields.field_mnk_slider_images ? (
                    <SingleContent content={post.content} images={post.fields.field_mnk_slider_images}/>
                  ):(
                    <div>{renderText(post.content)}</div>
                  )}
                </div>
              </div>
            </div>
            <Footer post={post}/>
          </div>
        ) : (
          <LoadingAnimation/>
        )}
      </div>
    );
  }
}

export default Single;
