import React, { Component } from 'react';
import SubSlideMenu from '../../components/nav/SubMenu/SubSlideMenu';
import { observer, inject } from 'mobx-react';

@inject('navigationStore', 'routerStore')
@observer
class SecondaryNav extends Component {

  menuItems = [];

  constructor(props) {
    super(props);
    if('concept' === this.props.terms) {
      this.menuItems = props.navigationStore.conceptsNavigationItems;
    } else if ('contest' === this.props.terms) {
      this.menuItems = props.navigationStore.contestNavigationItems;
    } else {
      this.menuItems = props.navigationStore.secondaryNavigationItems;
    }
  }

  render () {
    const currentPath = this.props.routerStore.location.pathname;
    const isCurrentMenuItem = this.props.navigationStore.isCurrentMenuItem;
    const handleClick = this.props.navigationStore.handleClick;

    return (
      <nav className="mink-second-nav">
        <div className="row">
          <div className="col-12">
            <div className="mink-second-nav__menu">
              <SubSlideMenu items={this.menuItems} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default SecondaryNav
