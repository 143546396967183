import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderText } from '../../helpers/api';
import VisibilitySensor from 'react-visibility-sensor';
import ScrollManager from 'window-scroll-manager';
import { WINDOW_EXISTS } from '../../helpers/viewport';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import PostsFilterGrid from '../../components/posts/PostsFilterGrid/PostsFilterGrid'
import Breadcrumbs from '../../components/nav/Breadcrumbs/Breadcrumbs';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';


@inject('archivesStore')
@observer
class Archive extends Component {

  path = '';
  canLoadMore = false;
  isBottomVisible = false;
  lastScrollPosition = 0;


  constructor(props) {
    super(props);
    this.path = this.props.match.path;
    this.ScrollManager = new ScrollManager();
    this.state = {
      canLoadMore: false,
      hasScrolled: false,
    }
  }

  renderHead() {
    if (this.props.archivesStore.isFetched.get(this.path) && this.props.archivesStore.data.meta) {
      return renderTitle(this.props.archivesStore.data.meta.title);
    } else {
      return null;
    }
  }

  componentDidMount() {
    this.path = this.props.match.path;
    this.props.archivesStore.fetchIfNeeded();
    document.body.classList.add('sanoa-archive');
    if (WINDOW_EXISTS) {
      window.addEventListener('window-scroll', this.onWindowScroll, {passive: true});
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('sanoa-archive');
    this.ScrollManager.removeListener();
    if (WINDOW_EXISTS) {
      window.removeEventListener('window-scroll', this.onWindowScroll, {passive: true});
    }
  }

  componentDidUpdate() {
    this.props.archivesStore.fetchIfNeeded();
    this.path = this.props.match.path;
    if (this.state.canLoadMore && this.isBottomVisible && !this.props.archivesStore.isFetching.get(this.path)) {
      this.props.archivesStore.fetchNext();
      this.setState({canLoadMore : false});
    }
  }

  onWindowScroll = (e) => {
    // activate after first user scrolls down event
    if (
      !this.state.hasScrolled &&
      this.lastScrollPosition !== 0 &&
      e.detail.scrollPosition > this.lastScrollPosition
    ) {
      this.setState({hasScrolled : true});
    }
    // reactivate on user scroll after fetch
    if (
      !this.state.canLoadMore && 
      !this.props.archivesStore.isFetching.get(this.path) &&
      e.detail.scrollPosition > this.lastScrollPosition
    ) {
      this.setState({canLoadMore : true});
    }
    this.lastScrollPosition = e.detail.scrollPosition;
  }

  onVisibiltyChange = (isVisible) => {
    this.isBottomVisible = isVisible;
    if (
      isVisible && 
      !this.props.archivesStore.isFetching.get(this.path) &&
      this.state.hasScrolled
    ) {
      this.props.archivesStore.fetchNext();
      this.setState({canLoadMore : false});
    }
  }

  render() {
    const isFetched = this.props.archivesStore.isFetched.get(this.path);
    const isFetching = this.props.archivesStore.isFetching.get(this.path);

    const posts = isFetched ? this.props.archivesStore.data[this.path].posts : [];
    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);
    return (
      <div className="kn-archive">
        <div className="kn-content">
        { isFetched ? (
          <div>
            {this.renderHead()}
            {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
            {<ScrollToHash/>}
            <div className="kn-page__header">
              <Breadcrumbs key={this.path} bc={this.props.archivesStore.data[this.path]._meta.bc}/>
              <h1>{renderText(this.props.archivesStore.data[this.path]._meta.label)}</h1>
              <div>{renderText(this.props.archivesStore.data[this.path]._meta.description)}</div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="-content">
                  <VisibilitySensor delayedCall={true} partialVisibility="bottom" onChange={this.onVisibiltyChange}>
                    {posts && <PostsFilterGrid key={this.path} posts={posts} direction="row"/>}
                  </VisibilitySensor>
                </div>
              </div>
              <div className="col-12">
                {isFetching && <LoadingAnimation/>}
              </div>
            </div>
          </div>
        ) : (
          <LoadingAnimation/>
        )}
        </div>
      </div>
    );
  }
}

export default Archive;
