import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {renderText} from '../../../helpers/api';
import { Link } from 'react-router-dom';
import YoutubeIFrameTeaser from '../../elements/YoutubeIFrame/YoutubeIFrameTeaser';

@inject('filterTermsStore')
@observer
class Teaser extends Component {

  isFiltered = () => {
    return this.props.terms.category && undefined !== this.props.terms.category.find((cat) => cat.slug === this.props.filterTermsStore.postsCatsIsFiltered);
  }
  render() {
    const post = this.props;
    const store = this.props.filterTermsStore;
    const show = !store.postsCatsIsFiltered || this.isFiltered();
    const video = post.youtube_thumb && post.youtube_thumb.url && (
      <YoutubeIFrameTeaser src={post.youtube_thumb.url} thumbnail={post.youtube_thumb.img}title={post.title}/>
    );
    const thumb = !video && post.post_thumbnail && (
      <div className="-image-wrapper">
        <div className="aspect-content">{renderText(post.post_thumbnail)}</div>
      </div>
    );

    if (show) {
      return (
        <div key={post.ID} className="kn-masonry__item">
          <div className="-wrapper">
            {video && video}
            {thumb && 
              <Link to={post.urlc.path}>{thumb}</Link>
            }
            <Link className="-text-link" to={post.urlc.path}>
              <div className="-text-wrapper">{renderText(post.title)}</div>
            </Link>
          </div>

        </div>
      )
    } else {
      return null;
    }

  }
}

export default Teaser;
