import {RouterStore} from 'mobx-react-router';
import NavigationStore from './UI/NavigationStore';
import WindowStore from './UI/WindowStore';
import ScrollStore from './UI/ScrollStore';
// import BlockDataStore from './UI/BlockDataStore';
import PagesStore from './PagesStore';
import SidebarsStore from './SidebarsStore';
import wpapi from 'wpapi';
import ScrollManager from 'window-scroll-manager';

import { setHeaders, getApiconfig } from '../helpers/api'
import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';

const scrollManager = new ScrollManager();
const wpApi = new wpapi(getApiconfig());
setHeaders(wpApi);

const routerStore = new RouterStore();
const windowStore = new WindowStore();
const scrollStore = new ScrollStore(routerStore, windowStore, scrollManager);

const browserHistory = createBrowserHistory();
export const history = syncHistoryWithStore(browserHistory, routerStore);

export default {
  routerStore: routerStore,
  navigationStore: new NavigationStore(routerStore),
  // blockDataStore: new BlockDataStore(cookieStore),
  windowStore: windowStore,
  pagesStore: new PagesStore(wpApi, routerStore),
  sidebarsStore: new SidebarsStore(wpApi, routerStore),
  scrollStore: scrollStore,
  // place for other stores...
};
