import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Nav from '../../containers/Nav/Nav';
import { getRandomInt } from '../../helpers/math';


@inject('windowStore')
@observer
class Header extends Component {

  bgPattern = 0;

  constructor(props) {
    super(props);
    this.bgPattern = getRandomInt(5) +1;
  }

  render() {
    return(
      <header className={"mink-header -color-" + this.bgPattern}>
        <Nav/>
      </header>
    );
  }
}
export default Header;
