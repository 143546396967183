import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { renderText } from '../../../helpers/api';


@observer
class Breadcrumbs extends Component {
  render() {
    return (
      <div className="kn-page__breadcrumb">{renderText(this.props.bc)}</div>
    );
  }
}

export default Breadcrumbs;