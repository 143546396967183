import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Masonry from 'react-masonry-css';
import {renderText} from '../../../helpers/api';
import Teaser from '../Teaser/Teaser';
import PostsFilter from '../PostsFilter/PostsFilter';

/**
 * A posts teaser Grid, used on Archive Page to display posts in masonry fashioned way
 * <PostsFilter> is used to filter a single term / category on click
 */
@inject('filterTermsStore')
@observer
class PostsFilterGrid extends Component {

  postMax = 0;
  //bootstrap breakpoints -1
  breakpointCols = {
    default: 3,
    1199: 2,
    767: 1,
  };

  componentDidMount() {
    this.props.filterTermsStore.addPostsFilterCats(this.props.posts);
  }

  componentWillUnmount() {
    this.props.filterTermsStore.resetPostsFilterCats();
  }

  render() {
    const posts = this.props.posts;
    // hacky way to get horizontal masonry for archives
    const dir = (this.props.direction && 'row' === this.props.direction) ? 'flex-md-row h-100' : '';
    return (
      <div>
        { this.props.title && <h2>{renderText(this.props.title)}</h2>}
        <PostsFilter/>
          <Masonry breakpointCols={this.breakpointCols} className={'kn-masonry ' + dir} columnClassName="kn-masonry_column">
            {posts.map((post, i) => {
              return (
                <Teaser key={post.id} {...post}/>
              );
            })}
          </Masonry>
      </div>
    );
  }

}

export default PostsFilterGrid;
