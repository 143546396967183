import React, { Component } from 'react';
import { Switch, matchPath} from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { ROUTES as routes , RouteWithSubRoutes } from './routes/routes';
import Header from './components/Header/Header';
// import Footer from './components/footer/Footer/Footer';


@inject('navigationStore')
@observer
class App extends Component {

  render() {
    // const toggleMap = this.props.navigationStore.toggleMap;
    const location = this.props.location;
    let match = null;
    for (let i=0; i< routes.length; i++) {
      match = matchPath(location.pathname, routes[i]);
      if (match) {
        break;
      }
    }

    return (
      <div className="wrap">
        <div className="container">
          <main className="mink-main">
            <Header/>

            <Switch key={match.path}>
                {routes.map((route, i) =>(
                  <RouteWithSubRoutes  key={i} {...route} />
                ))}
              </Switch>
          </main>
        </div>
      </div>
    );
  }
}

export default App;
