import React, { Component } from "react";
import Flickity from "react-flickity-component";
import { renderText } from '../../helpers/api';
import animateScrollTo from 'animated-scroll-to';

class SingleContent extends Component {
  /**
   * holds the dom ref of the sider
   */
  slider = {}
  navRef = React.createRef();
  navImages = [];
  // see https://flickity.metafizzy.co/api.html
  options = {
    freeScroll: false,
    freeScrollFriction: 0.04, // default 0.075
    selectedAttraction: 0.015, // default 0.025
    friction: 0.2, // default 0.28
    contain: true,
    adaptiveHeight: true,
    pageDots: false,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 65,
      y2: 45,
      x3: 20,
    },
  };

  componentDidMount(){
    // scroll sliderNavi to 2. image
    if(this.navRef.current && this.navImages[1].current){
      animateScrollTo(
        this.navImages[1].current, {
        elementToScroll: this.navRef.current,
        minDuration: 0,
        maxDuration: 0,
      });
    }
  }

  onCLickNavItem = (index) => (e) => {
    if (this.slider.isActive) {
      this.slider.select(index, false, true);
    }
    if(this.navRef.current && this.navImages[index].current){
      animateScrollTo(
        this.navImages[index].current, {
        elementToScroll: this.navRef.current,
        minDuration: 0,
        maxDuration: 0,
      });
    }
  }

  render() {
    const images = this.props.images;

    const slider = images.map((item, i) => {
      return (
        <div className="mink-slider__item" key={'slider-' + item.ID}>
          <div className="-img">
            <div className="aspect-content">
              <div className="h-100 d-flex align-items-center">
                <img
                  src={item.sizes.large}
                  className="align-self-center"
                  alt={item.title}
                  srcSet={item.sizes.large + " 1600w," + item.sizes.medium + " 800w"}
                  sizes="(max-width: 1600px) 100vw, 1600px"
                />
              </div>
            </div>
          </div>
        </div>
      );
    });

    const sliderNavi = images.map((item, i) => {
      this.navImages[i] = React.createRef();
      return (
        <div key={'navi-' + item.ID} onClick={this.onCLickNavItem(i)} ref={this.navImages[i]}>
          <div className="mink-slider__img">
            <div className="-img">
              <div className="aspect-content">
                <div className="h-100 d-flex align-items-center">
                  <img
                    src={item.sizes.medium}
                    className="align-self-center"
                    alt={item.title}
                    height={160}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="mink-single">
        <Flickity flickityRef={c => this.slider = c} className="mink-slider" options={this.options}>
          {slider}
        </Flickity>
        
        {images.length > 1 ? (
          <div className="row">
            <div className="col-12 col-lg-9">{renderText(this.props.content)}</div>
            <div className="mink-slider__navi d-none d-lg-block col-3" ref={this.navRef}>{sliderNavi}</div>
          </div>
        ):(
          <div className="row">
            <div className="col-12">{renderText(this.props.content)}</div>
          </div>
        )}
        
      </div>
    );
  }
}

export default SingleContent;
