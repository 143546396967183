import React, { Component } from 'react';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import LangSwitch from '../../components/nav/LangSwitch/LangSwitch';

@inject('navigationStore', 'routerStore')
@observer
class Nav extends Component {

  menuItems = [];

  constructor(props) {
    super(props);
    this.menuItems = props.navigationStore.primaryNavigationItems;
  }

  render () {
    const currentPath = this.props.routerStore.location.pathname;
    const isCurrentMenuItem = this.props.navigationStore.isCurrentMenuItem;
    const handleClick = this.props.navigationStore.handleClick;
    const toggleNav = this.props.navigationStore.toggleNav;

    return (
      <div>
        <div className="row">
          <div className="col-10 col-md-9">
            <h1 className="mink-header__brand"><Link to='/'>Coloured Fields</Link></h1>
          </div>
          <div className="col-2 col-md-3 align-self-end">
            <div className="d-flex justify-content-end">
              <div className="d-flex">
                <div className="align-self-end">
                  <LangSwitch/>
                </div>                
                <div className="mink-main-nav__toggler" onClick={toggleNav}>
                  <span className={'navbar-toggler-icon' + (this.props.navigationStore.navIsActive ? ' is_active' : '')}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <h3 className="mink-header__claim"><Link to='/'>creating communities</Link></h3>
          </div>
        </div>

        <nav className="mink-main-nav">
          <div className="row">
            <div className="col-12 col-lg-3 offset-lg-9">
              <div className={'mink-main-nav__container' + (this.props.navigationStore.navIsActive ? ' -show' : ' -hide')}>
                <div className="mink-gradient"></div>
                <div className="mink-main-nav__toggler" onClick={toggleNav}>
                  <span className={'navbar-toggler-icon' + (this.props.navigationStore.navIsActive ? ' is_active' : '')}></span>
                </div>
                <div className="mink-main-nav__menu">
                  <SubMenu items={this.menuItems} depthLevel={0} isCurrentMenuItem={isCurrentMenuItem} handleClick={handleClick} currentPath={currentPath}/>
                </div>
                
              </div>
            </div>
          </div>
        </nav>

      </div>

    );
  }
}

export default Nav
