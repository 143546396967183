import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import FilterTermButton from '../UI/FilterTermButton';

@inject('filterTermsStore')
@observer
class PostsFilter extends Component {

  render() {
    const categories = this.props.filterTermsStore.postsFilterCats;
    return (
      <div className="kn-posts-filter">
        {[...categories].map( (cat,i) => {
          return (
            <FilterTermButton key={cat[0]} active={cat[1].active} cat={cat[1]} onClick={this.props.filterTermsStore.onClickFilter}/>
          );
        })}
      </div>
    );
  }
}

export default PostsFilter
