import React, { Component } from 'react';
import { renderText } from '../../../helpers/api';
import SubMenu from './SubMenu';
import Flickity from "react-flickity-component";

class SubSlideMenu extends Component {

    options = {
        freeScroll: true,
        freeScrollFriction: 0.04,
        contain: true,
        // adaptiveHeight: true,
        pageDots: false,
        prevNextButtons: false,
        cellAlign: 'left'
    };
  
    hasCurrentChild = (children) => {
      let found = false;
      for (let i=0; i < children.length; i++) {
        found = this.props.isCurrentMenuItem(children[i].url_components);
        if (found) {
          break;
        } 
      }
      return found;
    }
    
  render () {
    const items = this.props.items;
    const level = this.props.depthLevel;
    const currentClass = 'current-menu-item';
    const handleClick = item => e => {
      const extern = item.classes.indexOf('nav-extern') !== -1;
      !extern && this.props.handleClick(e, item);
    };

    return (
        <Flickity flickityRef={c => this.slider = c} className="mink-second" options={this.options}>
          {
            items.map(item => {
              const hasCurrentChild = item.children && this.hasCurrentChild(item.children);
              const current = hasCurrentChild || this.props.isCurrentMenuItem(item.url_components);
              return (
                <div key={'menu_item_' + item.menu_item_id} className={'flex-fill mink-second__item ' + (current ? currentClass : '') + ' ' + (item.classes.join(' '))}>
                  <a href={item.url} className="level-1" onClick={handleClick(item)} target={item.target}>{renderText(item.title)}</a>
                  {
                    item.children && <SubMenu items={item.children} depthLevel={level + 1} handleClick={this.props.handleClick} isCurrentMenuItem={this.props.isCurrentMenuItem} currentPath={this.props.currentPath}/>
                  }
                </div>
              );
            })
          }
        </Flickity>
    );
  }
}

export default SubSlideMenu
